<template>
  <div class="form-container">
    <div class="form">
      <div class="form__container">
        <h3 class="form__title">Бронирование</h3>

        <Input
          label="Дата заезда"
          placeholder="Выберете дату"
          :no-pointer="true"
          icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333ZM6.66659 12.5H8.33325V14.1667H6.66659V12.5Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
          :is-error="true"
        />
        <Input
          label="Дата выезда"
          placeholder="Выберете дату"
          :no-pointer="true"
          icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333ZM6.66659 12.5H8.33325V14.1667H6.66659V12.5Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
        />
        <template v-if="!disableTime">
          <Input
            label="Время заезда"
            placeholder="Выберете время"
            :no-pointer="true"
            icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.5 11.6667L10 10V5.83333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
            :options="[
              { value: '12:00', label: ' - 1 200 ₽, ранний заезд' },
              { value: '15:00', label: ' - 1 600 ₽, ранний заезд' },
              { value: '16:00', label: ' - 2 600 ₽' },
              { value: '17:00', label: ' - 3 600 ₽' },
              { value: '18:00', label: ' - 4 600 ₽' },
              { value: '19:00', label: ' - 5 600 ₽' },
            ]"
          />
          <Input
            label="Время выезда"
            placeholder="Выберете время"
            :no-pointer="true"
            icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.5 11.6667L10 10V5.83333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
            :options="[
              { value: '12:00', label: ' - 1 200 ₽, ранний заезд' },
              { value: '15:00', label: ' - 1 600 ₽, ранний заезд' },
              { value: '16:00', label: ' - 2 600 ₽' },
              { value: '17:00', label: ' - 3 600 ₽' },
              { value: '18:00', label: ' - 4 600 ₽' },
              { value: '19:00', label: ' - 5 600 ₽' },
            ]"
          />
        </template>
        <Input
          v-else
          :full-width="true"
          :is-disable="true"
          value="Заезд с 14:00 до 22:00, выезд с 07:00 до 12:00"
          icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M12.5 11.6667L10 10V5.83333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
        />

        <Select class="form__select" />
      </div>
      <div class="form__container">
        <Input label="Имя" value="Айжан" />
        <Input label="Фамилия" value="Абдильдин" />
        <Input
          label="Телефон"
          value="+7-7273-12345"
          placeholder="Введите номер"
          icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M4.16667 3.33301H7.5L9.16667 7.49967L7.08333 8.74967C7.9758 10.5593 9.44039 12.0239 11.25 12.9163L12.5 10.833L16.6667 12.4997V15.833C16.6667 16.275 16.4911 16.699 16.1785 17.0115C15.866 17.3241 15.442 17.4997 15 17.4997C11.7494 17.3021 8.68346 15.9218 6.38069 13.619C4.07792 11.3162 2.69754 8.25029 2.5 4.99967C2.5 4.55765 2.67559 4.13372 2.98816 3.82116C3.30072 3.5086 3.72464 3.33301 4.16667 3.33301Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
          mask="+7 (###) ###-####"
        />
        <Input
          label="Доп. телефон (опционально)"
          placeholder="Введите номер"
          icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M4.16667 3.33301H7.5L9.16667 7.49967L7.08333 8.74967C7.9758 10.5593 9.44039 12.0239 11.25 12.9163L12.5 10.833L16.6667 12.4997V15.833C16.6667 16.275 16.4911 16.699 16.1785 17.0115C15.866 17.3241 15.442 17.4997 15 17.4997C11.7494 17.3021 8.68346 15.9218 6.38069 13.619C4.07792 11.3162 2.69754 8.25029 2.5 4.99967C2.5 4.55765 2.67559 4.13372 2.98816 3.82116C3.30072 3.5086 3.72464 3.33301 4.16667 3.33301Z' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
          mask="+7 (###) ###-####"
        />
        <Input
          label="Email (опционально)"
          placeholder="Введите email"
          icon="<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M13.3333 9.99987C13.3333 10.8839 12.9821 11.7318 12.357 12.3569C11.7319 12.982 10.8841 13.3332 10 13.3332C9.11594 13.3332 8.2681 12.982 7.64297 12.3569C7.01785 11.7318 6.66666 10.8839 6.66666 9.99987C6.66666 9.11582 7.01785 8.26797 7.64297 7.64285C8.2681 7.01773 9.11594 6.66654 10 6.66654C10.8841 6.66654 11.7319 7.01773 12.357 7.64285C12.9821 8.26797 13.3333 9.11582 13.3333 9.99987ZM13.3333 9.99987V11.2499C13.3333 11.8024 13.5528 12.3323 13.9435 12.723C14.3342 13.1137 14.8641 13.3332 15.4167 13.3332C15.9692 13.3332 16.4991 13.1137 16.8898 12.723C17.2805 12.3323 17.5 11.8024 17.5 11.2499V9.99987C17.5021 8.38829 16.985 6.81891 16.0253 5.5242C15.0657 4.22949 13.7145 3.27833 12.172 2.81162C10.6295 2.34491 8.97764 2.38747 7.4612 2.93301C5.94476 3.47854 4.64437 4.49802 3.75267 5.84044C2.86097 7.18285 2.42539 8.77678 2.51046 10.3861C2.59553 11.9955 3.19671 13.5346 4.22496 14.7755C5.25321 16.0164 6.65383 16.8932 8.21933 17.2758C9.78483 17.6584 11.4319 17.5266 12.9167 16.8999' stroke='#9E9E9E' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/>
        </svg>"
          type="email"
          :full-width="true"
        />
        <Input
          label="Ваши пожелания (опционально)"
          placeholder="Оставьте пожелания для владельца"
          :full-width="true"
          :is-textarea="true"
        />

        <div class="form__info-block">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.99991 7.50048V10.8338M9.99991 13.3338H10.0082M8.63574 2.99298L1.88074 14.2713C1.74149 14.5125 1.6678 14.7859 1.667 15.0644C1.6662 15.3428 1.73832 15.6166 1.87619 15.8586C2.01407 16.1005 2.21288 16.3022 2.45286 16.4434C2.69284 16.5847 2.96562 16.6607 3.24407 16.6638H16.7557C17.0341 16.6606 17.3067 16.5846 17.5466 16.4434C17.7864 16.3022 17.9852 16.1006 18.123 15.8588C18.2609 15.617 18.333 15.3433 18.3323 15.065C18.3316 14.7866 18.2581 14.5133 18.1191 14.2721L11.3641 2.99215C11.222 2.75757 11.0217 2.5636 10.7828 2.42898C10.5438 2.29436 10.2742 2.22363 9.99991 2.22363C9.72563 2.22363 9.45599 2.29436 9.21703 2.42898C8.97807 2.5636 8.77786 2.7584 8.63574 2.99298Z"
              stroke="#FFC107"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <div class="form__info-block-text">
            Обращаем внимание, что Хутор находится в приграничной зоне и всем
            гостям необходимо иметь с собой паспорт РФ. Гостям, не имеющим
            постоянной регистрации в Калининградской области, и зарубежным
            гостям необходимо оформить пропуск в приграничную зону на
            Госуслугах. Время заселения: с 14.00 до 20.00
          </div>
        </div>
      </div>
      <div class="form__price-block price-block">
        <h3 class="price-block__title">Стоимость</h3>
        <div class="price-block__row">
          <div class="price-block__left">Сумма за 12 ночей</div>
          <div class="price-block__right">100 440 ₽</div>
        </div>
        <div class="price-block__row">
          <div class="price-block__left">
            Скидка
            <Tooltip
              text="Деньги попадают сначала на счет платежной системы, а хосту только после вашего выезда...."
            />
          </div>
          <div class="price-block__right active">- 5 440,50 ₽</div>
        </div>
        <Promocode />
        <div class="form-line"></div>
        <div class="price-block__row">
          <div class="price-block__left big">Сумма к оплате сейчас</div>
          <div class="price-block__right big">48 303 ₽</div>
        </div>
        <div class="price-block__row">
          <div class="price-block__left">Оплатить при заезде</div>
          <div class="price-block__right">48 303,66 ₽</div>
        </div>
      </div>
    </div>
    <div class="form-notice">
      Нажимая «Забронировать», вы соглашаетесь с условиями
      <a href="">Пользовательского соглашения</a> и условиями
      <a href="">Оферты</a>.
    </div>
    <Button class="form-submit js-form-submit-button" size="big"
      >Забронировать
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.5 5L12.5 10L7.5 15"
          stroke="#ffffff"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </Button>
  </div>
</template>

<script>
import Button from "../button/Button.vue";
import Select from "../search/common/Select.vue";
import Input from "./Input.vue";
import Promocode from "./Promocode.vue";
import Tooltip from "./Tooltip.vue";

export default {
  props: {
    disableTime: Boolean,
  },
  components: { Input, Select, Tooltip, Promocode, Button },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@use "./form.scss" as *;
</style>
