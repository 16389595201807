<template>
  <div class="custom-calendar search-container">
    <div class="search">
      <div class="search__container">
        <div class="search__calendar" ref="input">
          <div class="search__input search__input-mobile">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333ZM6.66659 12.5H8.33325V14.1667H6.66659V12.5Z"
                stroke="#9E9E9E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="search__input-content">
              <div class="search__input-text">Заезд - выезд</div>
              <div class="search__input-value" id="mobileSelect">
                Выберите дату
              </div>
            </div>
            <svg
              class="search-icon"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.5 24.5L17.5 17.5M3.5 11.6667C3.5 12.7391 3.71124 13.8011 4.12165 14.7919C4.53206 15.7827 5.13362 16.683 5.89196 17.4414C6.65031 18.1997 7.55059 18.8013 8.54142 19.2117C9.53224 19.6221 10.5942 19.8333 11.6667 19.8333C12.7391 19.8333 13.8011 19.6221 14.7919 19.2117C15.7827 18.8013 16.683 18.1997 17.4414 17.4414C18.1997 16.683 18.8013 15.7827 19.2117 14.7919C19.6221 13.8011 19.8333 12.7391 19.8333 11.6667C19.8333 10.5942 19.6221 9.53224 19.2117 8.54142C18.8013 7.55059 18.1997 6.65031 17.4414 5.89196C16.683 5.13362 15.7827 4.53206 14.7919 4.12165C13.8011 3.71124 12.7391 3.5 11.6667 3.5C10.5942 3.5 9.53224 3.71124 8.54142 4.12165C7.55059 4.53206 6.65031 5.13362 5.89196 5.89196C5.13362 6.65031 4.53206 7.55059 4.12165 8.54142C3.71124 9.53224 3.5 10.5942 3.5 11.6667Z"
                stroke="#424242"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div class="search__input search__input_first hide-mobile">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333ZM6.66659 12.5H8.33325V14.1667H6.66659V12.5Z"
                stroke="#9E9E9E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="search__input-content">
              <div class="search__input-text">Заезд</div>
              <div class="search__input-value" id="vanilaStart">
                Выберите дату
              </div>
            </div>
          </div>
          <div class="search__input search__input_second hide-mobile">
            <svg
              class="search__input-icon"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3333 2.5V5.83333M6.66659 2.5V5.83333M3.33325 9.16667H16.6666M3.33325 5.83333C3.33325 5.39131 3.50885 4.96738 3.82141 4.65482C4.13397 4.34226 4.55789 4.16667 4.99992 4.16667H14.9999C15.4419 4.16667 15.8659 4.34226 16.1784 4.65482C16.491 4.96738 16.6666 5.39131 16.6666 5.83333V15.8333C16.6666 16.2754 16.491 16.6993 16.1784 17.0118C15.8659 17.3244 15.4419 17.5 14.9999 17.5H4.99992C4.55789 17.5 4.13397 17.3244 3.82141 17.0118C3.50885 16.6993 3.33325 16.2754 3.33325 15.8333V5.83333ZM6.66659 12.5H8.33325V14.1667H6.66659V12.5Z"
                stroke="#9E9E9E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div class="search__input-content">
              <div class="search__input-text">Выезд</div>
              <div class="search__input-value" id="vanilaEnd">
                Выберите дату
              </div>
            </div>
            <svg
              class="search-icon"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.5 24.5L17.5 17.5M3.5 11.6667C3.5 12.7391 3.71124 13.8011 4.12165 14.7919C4.53206 15.7827 5.13362 16.683 5.89196 17.4414C6.65031 18.1997 7.55059 18.8013 8.54142 19.2117C9.53224 19.6221 10.5942 19.8333 11.6667 19.8333C12.7391 19.8333 13.8011 19.6221 14.7919 19.2117C15.7827 18.8013 16.683 18.1997 17.4414 17.4414C18.1997 16.683 18.8013 15.7827 19.2117 14.7919C19.6221 13.8011 19.8333 12.7391 19.8333 11.6667C19.8333 10.5942 19.6221 9.53224 19.2117 8.54142C18.8013 7.55059 18.1997 6.65031 17.4414 5.89196C16.683 5.13362 15.7827 4.53206 14.7919 4.12165C13.8011 3.71124 12.7391 3.5 11.6667 3.5C10.5942 3.5 9.53224 3.71124 8.54142 4.12165C7.55059 4.53206 6.65031 5.13362 5.89196 5.89196C5.13362 6.65031 4.53206 7.55059 4.12165 8.54142C3.71124 9.53224 3.5 10.5942 3.5 11.6667Z"
                stroke="#424242"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <Select class="hide-mobile"></Select>

        <Button
          class="search__input-button hide-mobile"
          type="primary"
          size="big"
          target="_self"
          :button="true"
          @click="handleClick"
          >Найти</Button
        >
      </div>
      <Overlay class="vanilla-overlay" id="vanillaOverlay" />
    </div>
  </div>
</template>

<script>
import VanillaCalendar from "vanilla-calendar-pro";
import Select from "./common/Select.vue";
import Button from "../button/Button.vue";
import { createApp } from "vue";
import MobileSelect from "./common/MobileSelect.vue";
import Overlay from "../overlay/Overlay.vue";

const formatDate = (dateString) => {
  if (!dateString) {
    return "Выберите дату";
  }

  const months = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
  ];

  const dateParts = dateString.split("-");
  const month = months[parseInt(dateParts[1], 10) - 1];
  const day = dateParts[2];

  return `${day} ${month}`;
};

// тут ошибка самого вью, тк. не любит он монтировать несколько приложений.
function initVueComponent() {
  const app = createApp(MobileSelect);
  app.mount("#mobileChildSelect");

  const app2 = createApp(Button, {
    buttonText: "Найти",
    class: "mobile-select-button",
  });
  app2.mount("#mobileChildButton");
}

/*TODO: есть много логики внутри - changeToInput
если мы ресайзим экран. Текста обнуляются, но даты выбранные остаются. нужно ее продублировать в updateCalendar по идее.
итак я что-то много логики затронул.
+ при сбросе не обновляются инпуты на мобилке

*/

const params = {
  input: true,
  actions: {
    changeToInput(_e, self) {
      if (!self.HTMLInputElement) return;

      const isMobile = window.innerWidth < 1024;

      // для мобильного инпут
      if (self.selectedDates?.length) {
        if (isMobile) {
          const infoTitle = document.querySelector(
            ".vanilla-calendar-info__title"
          );
          const infoClear = document.querySelector(
            ".vanilla-calendar-info__clear"
          );
          const childSelectCalendar = document.querySelector(
            ".mobile-select__calendar"
          );
          const childSelectButton = childSelectCalendar?.querySelector(
            ".mobile-select__button"
          );
          const childSelectText = childSelectCalendar?.querySelector(
            ".mobile-select__text"
          );
          const MobileSelectButton = document.querySelector(
            "#mobileChildButton .mobile-select-button"
          );

          if (self.selectedDates[0]) {
            if (infoTitle) {
              infoTitle.textContent = "Выезд";
            }

            if (infoClear) {
              infoClear.classList.add("active");
            }
          } else {
            if (infoClear) {
              infoClear.classList.remove("active");
            }

            if (infoTitle) {
              infoTitle.textContent = "Заезд";
            }

            if (childSelectText) {
              childSelectText.textContent = "Заезд";
            }
          }

          if (childSelectButton) {
            childSelectButton.textContent = `${formatDate(
              self.selectedDates[0]
            )}`;
          }

          if (self.selectedDates[0] && self.selectedDates[1]) {
            const mobileSelect = document.querySelector("#mobileSelect");
            if (mobileSelect) {
              mobileSelect.textContent = `${formatDate(
                self.selectedDates[0]
              )} - ${formatDate(self.selectedDates[1])}`;
            }

            if (infoTitle) {
              infoTitle.textContent = "Заезд–Выезд";
            }

            if (childSelectText) {
              childSelectText.textContent = "Заезд–Выезд";
            }

            if (childSelectButton) {
              childSelectButton.textContent = `${formatDate(
                self.selectedDates[0]
              )} - ${formatDate(self.selectedDates[1])}`;
            }

            if (MobileSelectButton) {
              MobileSelectButton.classList.add("active");
            }
          } else {
            if (MobileSelectButton) {
              MobileSelectButton.classList.remove("active");
            }
          }
        }

        // для ПК инпутов
        const firstInput = document.querySelector(".search__input_first");
        const secondInput = document.querySelector(".search__input_second");

        if (firstInput && secondInput) {
          if (self.selectedDates[0]) {
            firstInput.classList.remove("hover");
            secondInput.classList.add("hover");
          }
          if (self.selectedDates[1]) {
            secondInput.classList.remove("hover");
          }
          if (!self.selectedDates[0] && !self.selectedDates[1]) {
            secondInput.classList.remove("hover");
          }
        }

        const start = document.querySelector("#vanilaStart");
        if (start) {
          start.classList.remove("error");
          start.textContent = formatDate(self.selectedDates[0]);
        }

        const end = document.querySelector("#vanilaEnd");
        if (end) {
          end.classList.remove("error");
          end.textContent = formatDate(self.selectedDates[1]);
        }

        const vanilaCalendarFooter = document.querySelector(
          "#vanilaCalendarFooter"
        );

        // ПК и Мобилка
        if (vanilaCalendarFooter) {
          if (self.selectedDates[0]) {
            vanilaCalendarFooter.classList.remove("error");

            vanilaCalendarFooter.textContent = `Заезд ${formatDate(
              self.selectedDates[0]
            )} - Выберите дату выезда`;
          }
          if (self.selectedDates[1]) {
            vanilaCalendarFooter.classList.remove("error");

            vanilaCalendarFooter.textContent = `Заезд ${formatDate(
              self.selectedDates[0]
            )} - выезд ${formatDate(self.selectedDates[1])}`;
          }
          if (!self.selectedDates[0] && !self.selectedDates[1]) {
            vanilaCalendarFooter.textContent = `Выдерите дату заезда`;
            vanilaCalendarFooter.classList.add("error");
          }
        }

        return;
      }

      self.HTMLInputElement.value = "";
    },
    hideCalendar() {
      const overlay = document.querySelector("#vanillaOverlay");

      if (overlay) {
        overlay.classList.remove("active");
      }

      // для ПК инпутов hover
      const firstInput = document.querySelector(".search__input_first");
      const secondInput = document.querySelector(".search__input_second");

      firstInput && firstInput.classList.remove("hover");
      secondInput && secondInput.classList.remove("hover");

      document.body.style.overflow = "";
    },
    showCalendar(event) {
      const overlay = document.querySelector("#vanillaOverlay");
      if (overlay && window.innerWidth < 1024) {
        overlay.classList.add("active");

        document.body.style.overflow = "hidden";
      }

      if (!event.selectedDates[0]) {
        const firstInput = document.querySelector(".search__input_first");

        if (firstInput) {
          firstInput.classList.add("hover");
        }
      }
    },
    updateCalendar(self) {
      const infoClear = document.querySelector(".vanilla-calendar-info__clear");

      if (infoClear) {
        infoClear.addEventListener("click", () => {
          self.selectedDates = [];
          self.update();
          self.HTMLElement.classList.remove("child-select-open");
        });

        if (self.selectedDates.length) {
          infoClear.classList.add("active");
        }
      }

      if (window.innerWidth < 1024) {
        initVueComponent();
      }

      if (!self.selectedDates.length) {
        // для ПК инпутов
        const start = document.querySelector("#vanilaStart");
        if (start) {
          start.textContent = "Выберите дату";
        }

        const end = document.querySelector("#vanilaEnd");
        if (end) {
          end.textContent = "Выберите дату";
        }
        // для мобилки
        const mobileSelect = document.querySelector("#mobileSelect");

        if (mobileSelect) {
          mobileSelect.textContent = `Выберите дату`;
        }
      }
    },
  },
  type: "multiple",
  settings: {
    lang: "ru",
    selection: {
      day: "multiple-ranged",
      month: "only-arrows",
      year: "only-arrows",
    },
    visibility: {
      positionToInput: "center",
      daysOutside: false,
    },
    range: {
      disablePast: true,
      disableGaps: true,
      disabled: ["2024-11-01:2024-11-05", "2024-12-02"],
      edgesOnly: true,
    },
  },
  DOMTemplates: {
    multiple: `
      <div class="vanilla-calendar-controls">
        <#ArrowPrev />
        <#ArrowNext />
      </div>
      <div class="vanilla-calendar-grid">
        <#Multiple>
          <div class="vanilla-calendar-column">
            <div class="vanilla-calendar-header">
              <div class="vanilla-calendar-header__content">
                <#Month />
                <#Year />
              </div>
            </div>
            <div class="vanilla-calendar-wrapper">
              <#WeekNumbers />
              <div class="vanilla-calendar-content">
                <#Week />
                <#Days />
              </div>
            </div>
          </div>
        <#/Multiple>
      </div>
      <div class="vanilla-calendar-footer_line"></div>
      <div class="vanilla-calendar-footer">
        <p class="vanilla-calendar-footer__title" id="vanilaCalendarFooter">Выберите дату заезда</p>
        <div class="vanilla-calendar-footer__notice">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 7.5H10.0083M9.16667 10H10V13.3333H10.8333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z" stroke="#9E9E9E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <span>Бронирование минимум на 2 дня, при проживании от 7 дней скидка 10%</span>
        </div>
      </div>
    `,
    default: `
      <div class="vanilla-calendar-container">
        <div class="vanilla-calendar-info">
          <div class="vanilla-calendar-info__title">Заезд</div>
          <button class="vanilla-calendar-info__clear">Сбросить</button>
        </div>
        <div class="vanilla-calendar-header">
          <#ArrowPrev />
          <div class="vanilla-calendar-header__content">
            <#Month />
            <#Year />
          </div>
          <#ArrowNext />
        </div>
        <div class="vanilla-calendar-wrapper">
          <#WeekNumbers />
          <div class="vanilla-calendar-content">
            <#Week />
            <#Days />
          </div>
        </div>
        <div class="vanilla-calendar-footer">
          <p class="vanilla-calendar-footer__title" id="vanilaCalendarFooter">Выберите дату заезда</p>
          <div class="vanilla-calendar-footer__notice">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 7.5H10.0083M9.16667 10H10V13.3333H10.8333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10Z" stroke="#9E9E9E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>Бронирование минимум на 2 дня, при проживании от 7 дней скидка 10%</span>
          </div>
        </div>
        <div id="mobileChildSelect"></div>
        <div id="mobileChildButton"></div>
      </div>
    `,
  },
  popups: {
    "2024-12-01": {
      html: `<p class="vanilla-calendar-text vanilla-calendar-text_active">1 000 ₽</p>`,
    },
    "2024-12-02": {
      html: `<p class="vanilla-calendar-text">10 000 ₽</p>`,
    },
    "2024-12-03": {
      html: `<p class="vanilla-calendar-text">100 000 ₽</p>`,
    },
    "2024-12-04": {
      html: `<p class="vanilla-calendar-text vanilla-calendar-text_loading"></p>`,
    },
    "2024-12-05": {
      html: `<p class="vanilla-calendar-text">1 000 000 ₽</p>`,
    },
    "2024-12-20": {
      html: `<p class="vanilla-calendar-text vanilla-calendar-text_active">1 000 ₽</p>`,
    },
  },
};

export default {
  data() {
    return {
      calendar: null,
      isMobile: false,
      isCalendarOpen: false,
      isChildSeletOpen: false,
    };
  },
  components: {
    Select,
    Button,
    Overlay,
  },
  mounted() {
    // Создаем экземпляр календаря
    this.calendar = new VanillaCalendar(this.$refs.input, params);
    this.calendar.init(); // Инициализируем календарь

    const mediaQuery = window.matchMedia("(max-width: 1023px)");

    this.handleMediaChange(mediaQuery);

    // Проверяем, поддерживается ли addEventListener
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener("change", this.handleMediaChange);
    } else if (mediaQuery.addListener) {
      mediaQuery.addListener(this.handleMediaChange); // Используем addListener для iOS
    }
  },
  beforeUnmount() {
    const mediaQuery = window.matchMedia("(max-width: 1023px)");
    // Проверяем, поддерживается ли addEventListener
    if (mediaQuery.addEventListener) {
      mediaQuery.removeEventListener("change", this.handleMediaChange);
    } else if (mediaQuery.addListener) {
      mediaQuery.removeListener(this.handleMediaChange); // Используем addListener для iOS
    }
  },
  methods: {
    handleClick(event) {
      event.preventDefault();

      if (!this.calendar) {
        return;
      }

      const start = document.querySelector("#vanilaStart");
      if (start && !this.calendar?.selectedDates[0]) {
        start.classList.add("error");
      }

      const end = document.querySelector("#vanilaEnd");
      if (end && !this.calendar?.selectedDates[1]) {
        end.classList.add("error");
      }
    },
    handleMediaChange(event) {
      this.isMobile = event.matches;

      if (event.matches) {
        this.calendar.type = "default";
        this.calendar.update();
        this.calendar.hide();
      } else {
        this.calendar.type = "multiple";
        this.calendar.update();
        this.calendar.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-calendar.search-container {
    padding: 12px 16px 12px;
    background-color: $white;
    z-index: 1000;
    position: sticky;
    top: 0;
}
.custom-calendar .search {
    &__container {
        box-sizing: border-box;
        @include container;
        background-color: $main500;
        border-radius: 12px;
        padding: 4px;
        height: 66px;
        display: flex;
    }

    &__calendar {
        width: 100%;
        display: flex;
    }

    @include InputsStyles;

    &__input-button {
        min-height: 100%;
        margin-left: 2px;
    }

    & .hide-mobile {
        @media (max-width: 1023px) {
            display: none;
        }
    }

    & .search-icon {
        display: none;

        @include hoverAndActive {
            opacity: 0.9;
        }

        @media (max-width: 1023px) {
            display: block;
            margin: 0;
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__input-mobile {
        display: none;

        @media (max-width: 1023px) {
            display: flex;
            @media (max-width: 1023px) {
                border-radius: 8px;
                width: 100%;
            }
        }
    }
}
</style>
